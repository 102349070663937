"use strict";

import Swiper from 'swiper';
import Draggabilly from 'draggabilly'

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var swiperParams = {
  freeMode: true,
  grabCursor: true,
  breakpoints: {
    1280: {
      slidesPerView: 2.7,
      spaceBetween: 20
    },
    600: {
      slidesPerView: 2.4,
      spaceBetween: 15
    },
    375: {
      slidesPerView: 1.5,
      slidesOffsetBefore: 12,
      slidesOffsetAfter: 12,
      spaceBetween: 12
    },
    320: {
      slidesPerView: 1.25,
      slidesOffsetBefore: 12,
      slidesOffsetAfter: 12,
      spaceBetween: 12
    }
  }
};

var userImageEl = document.getElementById('userImage');
var dropdownMenuEl = document.getElementById('accountDropdown');
window.addEventListener('click', function (event) {
  if (!(userImageEl && dropdownMenuEl)) return;
  dropdownMenuEl.style.display = 'none';

  if (userImageEl.contains(event.target) || dropdownMenuEl.contains(event.target)) {
    dropdownMenuEl.style.display = 'block';
  }
});

(function () {
  var bestAnswerLabelActivators = document.querySelectorAll('[data-toggle="bestAnswerLabel"]');

  if (bestAnswerLabelActivators) {
    var _iterator = _createForOfIteratorHelper(bestAnswerLabelActivators),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var activator = _step.value;
        activator.addEventListener('click', setAnswerAsTheBest);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }

  function setAnswerAsTheBest() {
    var _this = this;

    if (this.classList.contains('best-answer-toggler--active')) return;
    var answerCardEl = this.closest('.answer-card');
    var bestAnswerLabelEl = answerCardEl.querySelector('.answer-card__best-answer-label');
    var contentEl = this.querySelector('.best-answer-toggler__content span');
    var switchEl = this.querySelector('.item-toggle');
    var cancelBtn = this.querySelector('.best-answer-toggler__cancel-btn');
    bestAnswerLabelEl.style.display = 'flex';
    cancelBtn.style.display = 'block';
    switchEl.style.display = 'none';
    this.classList.add('best-answer-toggler--active');
    contentEl.text = 'You have chosen this answer as the best one!';
    cancelBtn.addEventListener('click', function (event) {
      event.stopPropagation();
      bestAnswerLabelEl.style.display = 'none';
      switchEl.style.display = 'block';
      cancelBtn.style.display = 'none';

      _this.classList.remove('best-answer-toggler--active');

      contentEl.text = 'This is the best answer to my question';
    }, {
      once: true
    });
  }
})();

var bottomSheet = document.getElementById('bottomSheet');
var bottomSheetContent = bottomSheet && bottomSheet.querySelector('.bottom-sheet__content');
var bsOverlay = document.querySelector('.bs-overlay');
var bottomSheetCloseActivators = document.querySelectorAll('[data-target="closeBottomSheet"]');

function showBottomSheet() {
  if (bottomSheet) {
    document.body.classList.add('app-no-scroll');
    bottomSheet.classList.remove('bottom-sheet-wrapper--hidden');
    bsOverlay.classList.remove('bs-overlay--hidden');
    bottomSheetContent.style.display = 'block';
    setTimeout(function () {
      bottomSheet.style.transform = 'translate3d(0, 0, 0)';
      bsOverlay.style.opacity = '0.4';
      bsOverlay.addEventListener('click', hideBottomSheet);
    }, 10);
  }
}

function hideBottomSheet() {
  if (bottomSheet) {
    bottomSheet.style.transform = 'translate3d(0, 100%, 0)';
    bsOverlay.style.opacity = 0;
    document.body.classList.remove('app-no-scroll');
    bottomSheet.addEventListener('transitionend', function () {
      bottomSheet.classList.add('bottom-sheet-wrapper--hidden');
      bsOverlay.classList.add('bs-overlay--hidden');
      bsOverlay.removeEventListener('click', hideBottomSheet);

      var _iterator2 = _createForOfIteratorHelper(bottomSheetContent.children),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var el = _step2.value;
          el.style.display = 'none';
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      ;
    }, {
      once: true
    });
  }
}

var _iterator3 = _createForOfIteratorHelper(bottomSheetCloseActivators),
    _step3;

try {
  for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
    var el = _step3.value;
    el.addEventListener('click', hideBottomSheet);
  }
} catch (err) {
  _iterator3.e(err);
} finally {
  _iterator3.f();
}

if (bottomSheet) {
  var draggie = new Draggabilly(bottomSheet, {
    axis: 'y'
  });
  var dragDownVector = 0;
  var dragStartPosition = 0;
  draggie.on('dragMove', function (event, pointer, moveVector) {
    if (dragStartPosition - draggie.position.y >= 100) {
      draggie._pointerUp(event.originalEvent, pointer);
    }

    dragDownVector = moveVector.y;
  });
  draggie.on('pointerDown', function () {
    draggie.enable();
    dragDownVector = 0;
    dragStartPosition = bottomSheet.offsetTop;
  });
  draggie.on('pointerUp', function () {
    if (dragDownVector >= 40) {
      hideBottomSheet();
    } else {
      draggie.disable();
      bottomSheet.style.transform = 'translate3d(0, 0, 0)';
    }
  });
}

function showModal(element) {
  if (!element) return;
  element.style.display = 'block';
  document.body.style.paddingRight = "".concat(window.innerWidth - document.documentElement.clientWidth, "px");
  document.body.classList.add('app-no-scroll');
  element.insertAdjacentHTML('afterend', '<div class="modal-overlay"></div>');
}

function hideModal(element) {
  if (!element) return;
  element.style.display = 'none';
  document.querySelector('.modal-overlay').remove();
  document.body.style.paddingRight = null;
  document.body.classList.remove('app-no-scroll');
}

var shortDescEls = document.getElementsByClassName('question-card__content__short-desc');

var _iterator4 = _createForOfIteratorHelper(shortDescEls),
    _step4;

try {
  var _loop = function _loop() {
    var elem = _step4.value;
    elem.addEventListener('click', function () {
      elem.nextElementSibling.style.display = 'block';
      elem.remove();
    });
  };

  for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
    _loop();
  }
} catch (err) {
  _iterator4.e(err);
} finally {
  _iterator4.f();
}

var qNotificationsToggler = document.querySelectorAll('[data-target="qNotificationsToggler"]');
var qTopDropdownEl;
var qBottomDropdownEl;

function generateQTopDropdownEl(x, y) {
  var xPos = x - 195;
  var yPos = y + 55 + window.scrollY;
  qTopDropdownEl.style.transform = "translate3d(".concat(xPos, "px, ").concat(yPos, "px, 0px)");
  qTopDropdownEl.style.display = 'block';
}

function generateQBottomDropdownEl(x, y) {
  var xPos = x - 90;
  var yPos = y - 120 + window.scrollY;
  qBottomDropdownEl.style.transform = "translate3d(".concat(xPos, "px, ").concat(yPos, "px, 0px)");
  qBottomDropdownEl.style.display = 'block';
}

window.addEventListener('click', function (event) {
  if (qTopDropdownEl && !qTopDropdownEl.contains(event.target)) {
    qTopDropdownEl.style.display = 'none';
  }

  if (qBottomDropdownEl && !qBottomDropdownEl.contains(event.target)) {
    qBottomDropdownEl.style.display = 'none';
  }

  if (event.target.dataset.target === 'qcTopDropdown') {
    var _event$target$getBoun = event.target.getBoundingClientRect(),
        left = _event$target$getBoun.left,
        top = _event$target$getBoun.top;
    qTopDropdownEl = document.getElementById('qTopDropdown');
    generateQTopDropdownEl(left, top);
  } else if (event.target.dataset.target === 'qcBottomDropdown') {
    var _event$target$getBoun2 = event.target.getBoundingClientRect(),
        _left = _event$target$getBoun2.left,
        _top = _event$target$getBoun2.top;

    qBottomDropdownEl = document.getElementById('qBottomDropdown');
    generateQBottomDropdownEl(_left, _top);
  }

  if (event.target.dataset.target == 'qcTopMenu') {
    document.getElementById('qcTopMenu').style.display = 'block';
    showBottomSheet();
  } else if (event.target.dataset.target == 'qcBottomMenu') {
    document.getElementById('qcBottomMenu').style.display = 'block';
    showBottomSheet();
  }
});

var _iterator5 = _createForOfIteratorHelper(qNotificationsToggler),
    _step5;

try {
  for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
    var toggler = _step5.value;
    toggler.addEventListener('click', function () {
      var checkboxEl = this.querySelector('input[type="checkbox"]');
      var bellIcon = this.querySelector('.question-dropdown__item__icon .bell');
      var bellCrossedIcon = this.querySelector('.question-dropdown__item__icon .bell-crossed');
      checkboxEl.checked = !checkboxEl.checked;
      if (!(bellIcon && bellCrossedIcon)) return;

      if (checkboxEl.checked) {
        bellCrossedIcon.style.display = 'none';
        bellIcon.style.display = 'block';
      } else {
        bellIcon.style.display = 'none';
        bellCrossedIcon.style.display = 'block';
      }
    });
  }
} catch (err) {
  _iterator5.e(err);
} finally {
  _iterator5.f();
}

var searchBarActivators = document.querySelectorAll('[data-target="searchBar"]');
var searchBarEl = document.getElementById('searchBar');

var _iterator6 = _createForOfIteratorHelper(searchBarActivators),
    _step6;

try {
  for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
    var activator = _step6.value;
    activator.addEventListener('click', function () {
      if (!searchBarEl) return;

      if (searchBarEl.classList.contains('search-bar--visible')) {
        searchBarEl.classList.remove('search-bar--visible');
        if (bottomNav) bottomNav.style.bottom = '24px';
      } else {
        searchBarEl.classList.add('search-bar--visible');
        if (bottomNav) bottomNav.style.bottom = '-96px';
        setTimeout(function () {
          return searchBarEl.querySelector('.search-bar__input').focus();
        }, 100);
      }
    });
  }
} catch (err) {
  _iterator6.e(err);
} finally {
  _iterator6.f();
}

document.addEventListener('DOMContentLoaded', function () {
  var swiperEls = document.getElementsByClassName('base-swiper');

  for (var i = 0; i < swiperEls.length; i++) {
    swiperEls[i].querySelector('.swiper-container').classList.add("swiper-".concat(i + 1));
    swiperEls[i].querySelector('.swiper-button-prev').classList.add("btn-prev-".concat(i + 1));
    swiperEls[i].querySelector('.swiper-button-next').classList.add("btn-next-".concat(i + 1));
    new Swiper(".swiper-".concat(i + 1), _objectSpread({}, swiperParams, {
      navigation: {
        nextEl: ".btn-next-".concat(i + 1),
        prevEl: ".btn-prev-".concat(i + 1)
      }
    }));
  }
});

(function () {
  var addAvatarReminder = document.getElementById('add-avatar-reminder');
  var avatarReminderCloseBtn = document.querySelector('[data-target-close="add-avatar-reminder"]');

  if (addAvatarReminder && avatarReminderCloseBtn) {
    avatarReminderCloseBtn.addEventListener('click', function () {
      addAvatarReminder.remove();
    });
  }
})();

var signInLink = document.querySelector('.top-navigation__link--highlighted');
var hamburgerMenu = document.getElementById('hamburgerMenu');
var hamburgerMenuActivator = document.querySelector('[data-target="hamburgerMenu"]');

function animateSignInLink(el, dashoffset) {
  dashoffset -= 2;

  if (dashoffset < 0) {
    dashoffset = 0;
  }

  el.style.strokeDashoffset = dashoffset + 'px';

  if (dashoffset > 0) {
    setTimeout(function () {
      return animateSignInLink(el, dashoffset);
    }, Math.abs(dashoffset) * 2);
  }
}

signInLink && signInLink.addEventListener('mouseenter', function () {
  var _iterator7 = _createForOfIteratorHelper(signInLink.querySelectorAll('.sign-in-line')),
      _step7;

  try {
    for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
      var line = _step7.value;
      setTimeout(animateSignInLink(line, 22), 10);
    }
  } catch (err) {
    _iterator7.e(err);
  } finally {
    _iterator7.f();
  }
});
hamburgerMenuActivator && hamburgerMenuActivator.addEventListener('click', function () {
  this.classList.toggle('top-navigation__hamburger-icon--open');
  hamburgerMenu.classList.toggle('hamburger-menu--visible');
  document.documentElement.classList.toggle('app-no-scroll');
  document.body.classList.toggle('app-no-scroll');
});

(function () {
  var commentFormActivator = document.querySelector('[data-target="new-comment"]');
  var commentFormCloseActivator = document.querySelector('[data-target-close="new-comment"]');
  var newCommentForm = document.getElementById('new-comment');
  var newCommentSendBtn = newCommentForm && newCommentForm.querySelector('.new-comment-form__send-btn');

  function handleCommentTextareaChange(event) {
    var isSendBtnActive = Boolean(event.target.value && event.target.value.length);
    newCommentSendBtn.disabled = !isSendBtnActive;
  }

  commentFormActivator && commentFormActivator.addEventListener('click', function () {
    var textareaEl = newCommentForm.querySelector('.new-comment-form__textarea');
    this.parentElement.style.display = 'none';
    newCommentForm.style.display = 'flex';
    setTimeout(function () {
      return textareaEl.focus();
    });
    textareaEl.addEventListener('input', handleCommentTextareaChange);
  });
  commentFormCloseActivator && commentFormCloseActivator.addEventListener('click', function () {
    var textareaEl = newCommentForm.querySelector('.new-comment-form__textarea');
    newCommentForm.style.display = 'none';
    commentFormActivator.parentElement.style.display = 'flex';
    textareaEl.removeEventListener('input', handleCommentTextareaChange);
    textareaEl.value = '';
    textareaEl.style.height = null;
    newCommentSendBtn.disabled = true;
  });
})();

var bottomMenu = document.getElementById('bottomMenu');

if (bottomMenu) {
  var input = bottomMenu.querySelector('input');
  input.addEventListener('focus', function () {
    var topPosition = window.scrollY + (window.innerHeight - bottomSheet.offsetHeight) + 100 + 'px';
    bottomSheet.classList.add('bs--keyboard-active');
    bottomSheet.style.setProperty('top', topPosition, 'important');
  });
  input.addEventListener('blur', function () {
    setTimeout(function () {
      bottomSheet.style.setProperty('top', 'auto', 'important');
      bottomSheet.classList.remove('bs--keyboard-active');
    }, 300);
  });
}

(function () {
  var bsOtherProfileMenu = document.getElementById('bsOtherProfileMenu');
  var bsOtherProfileMenuActivators = document.querySelectorAll('[data-target="bsOtherProfileMenu"]');

  var _iterator8 = _createForOfIteratorHelper(bsOtherProfileMenuActivators),
      _step8;

  try {
    for (_iterator8.s(); !(_step8 = _iterator8.n()).done;) {
      var activator = _step8.value;
      activator.addEventListener('click', function () {
        // console.log(bsOtherProfileMenu);
        bsOtherProfileMenu.style.display = 'block';
        showBottomSheet();
      });
    }
  } catch (err) {
    _iterator8.e(err);
  } finally {
    _iterator8.f();
  }
})();

var bsAuthorSelect = document.getElementById('bsAuthorSelect');
var bsAuthorSelectActivators = document.querySelectorAll('[data-target="bsAuthorSelect"]');

var _iterator9 = _createForOfIteratorHelper(bsAuthorSelectActivators),
    _step9;

try {
  for (_iterator9.s(); !(_step9 = _iterator9.n()).done;) {
    var _activator = _step9.value;

    _activator.addEventListener('click', function () {
      bsAuthorSelect.style.display = 'block';
      showBottomSheet();
    });
  }
} catch (err) {
  _iterator9.e(err);
} finally {
  _iterator9.f();
}

(function () {
  var bsCoverUpload = document.getElementById('bsCoverUpload');
  var bsCoverUploadActivators = document.querySelectorAll('[data-target="bsCoverUpload"]');

  var _iterator10 = _createForOfIteratorHelper(bsCoverUploadActivators),
      _step10;

  try {
    for (_iterator10.s(); !(_step10 = _iterator10.n()).done;) {
      var activator = _step10.value;
      activator.addEventListener('click', function () {
        bsCoverUpload.style.display = 'block';
        showBottomSheet();
      });
    }
  } catch (err) {
    _iterator10.e(err);
  } finally {
    _iterator10.f();
  }
})();

var bsThemesSelect = document.getElementById('bsThemesSelect');
var bsThemesSelectActivators = document.querySelectorAll('[data-target="bsThemesSelect"]');

var _iterator11 = _createForOfIteratorHelper(bsThemesSelectActivators),
    _step11;

try {
  for (_iterator11.s(); !(_step11 = _iterator11.n()).done;) {
    var _activator2 = _step11.value;

    _activator2.addEventListener('click', function () {
      bsThemesSelect.style.display = 'block';
      showBottomSheet();
    });
  }
} catch (err) {
  _iterator11.e(err);
} finally {
  _iterator11.f();
}

(function () {
  var bsUpdateProfile = document.getElementById('bsUpdateProfile');
  var bsUpdateProfileActivators = document.querySelectorAll('[data-target="bsUpdateProfile"]');

  function handleBsUpdateProfileClick(event) {
    var currentMenuItem = event.target.closest('.update-profile-menu__item-wrapper');

    if (currentMenuItem) {
      hideBottomSheet();
      bottomSheet.addEventListener('transitionend', function () {
        if (!currentMenuItem.dataset.target && bsCoverUpload) {
          bsCoverUpload.style.display = 'block';
          showBottomSheet();
        }
      }, {
        once: true
      });
    }
  }

  var _iterator12 = _createForOfIteratorHelper(bsUpdateProfileActivators),
      _step12;

  try {
    for (_iterator12.s(); !(_step12 = _iterator12.n()).done;) {
      var activator = _step12.value;
      activator.addEventListener('click', function () {
        bsUpdateProfile.style.display = 'block';
        bsUpdateProfile.addEventListener('click', handleBsUpdateProfileClick);
        showBottomSheet();
      });
    }
  } catch (err) {
    _iterator12.e(err);
  } finally {
    _iterator12.f();
  }
})();

var scrollToTopButton = document.getElementById('scrollToTop');
scrollToTopButton && scrollToTopButton.addEventListener('click', function () {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});
var answerModalActivators = document.querySelectorAll('[data-target="answerModal"]');
var answerModalCloseActivators = document.querySelectorAll('[data-target-close="answerModal"]');
var uploadImageActivator;
var addLinkActivator;

function handleAnswerModalClick(event) {
  uploadImageActivator.nextElementSibling.style.display = 'none';
  addLinkActivator.nextElementSibling.style.display = 'none';
  userSelectDropdownActivator.nextElementSibling.style.display = 'none';

  var isAnswerModalCloseActivatorClicked = _toConsumableArray(answerModalCloseActivators).some(function (activator) {
    return activator.contains(event.target);
  });

  if (uploadImageActivator.contains(event.target)) {
    uploadImageActivator.nextElementSibling.style.display = 'block';
  } else if (addLinkActivator.contains(event.target)) {
    addLinkActivator.nextElementSibling.style.display = 'block';
  } else if (userSelectDropdownActivator.contains(event.target)) {
    userSelectDropdownActivator.nextElementSibling.style.display = 'block';
  } else if (isAnswerModalCloseActivatorClicked || !this.firstElementChild.contains(event.target)) {
    hideModal(this);
    this.removeEventListener('click', handleAnswerModalClick);
    uploadImageActivator.nextElementSibling.removeEventListener('click', stopPropagationOnDropdown);
    addLinkActivator.nextElementSibling.removeEventListener('click', stopPropagationOnDropdown);
    answerModal.querySelector('#answerModalContent').removeEventListener('input', handleAnswerModalContentChange);
  }
}

function stopPropagationOnDropdown(event) {
  event.stopPropagation();
}

function handleAnswerModalContentChange() {
  var symbolsCounter = answerModal.querySelector('.symbols-counter-container');
  var submitBtn = answerModal.querySelector('.answer-modal__answer-button');
  var svgFillCircle = symbolsCounter.querySelector('.symbols-counter__fill-circle');
  var svgBgCircle = symbolsCounter.querySelector('.symbols-counter__bg-circle');
  var symbolsCounterLabel = symbolsCounter.querySelector('.symbols-counter__label__text');
  var checkIcon = symbolsCounter.querySelector('.symbols-counter__label .material-icons-round');
  var svgStrokeDasharray = svgFillCircle.style.strokeDasharray;
  var symbolsLimit = parseInt(symbolsCounter.dataset.symbolsLimit, 10);
  var counterStep = svgStrokeDasharray / symbolsLimit;
  var answerLength = event.target.value.length;
  var newStrokeDashoffset;
  var currentSymbols;

  if (symbolsCounter.dataset.symbolsCountdown !== undefined) {
    newStrokeDashoffset = answerLength * counterStep;
    currentSymbols = symbolsLimit - answerLength;
  } else {
    newStrokeDashoffset = svgStrokeDasharray - answerLength * counterStep;
    currentSymbols = answerLength;
  }

  symbolsCounter.style.opacity = currentSymbols >= 1 ? 1 : 0;

  if (currentSymbols >= symbolsLimit) {
    symbolsCounterLabel.style.display = 'none';
    checkIcon.style.display = 'block';
    svgBgCircle.style.strokeOpacity = 1;
    submitBtn.disabled = false;
  } else {
    checkIcon.style.display = 'none';
    symbolsCounterLabel.style.display = 'block';
    svgBgCircle.style.strokeOpacity = 0.07;
    submitBtn.disabled = true;
  }

  symbolsCounterLabel.textContent = currentSymbols;
  svgFillCircle.style.strokeDashoffset = newStrokeDashoffset;
}

var _iterator13 = _createForOfIteratorHelper(answerModalActivators),
    _step13;

try {
  for (_iterator13.s(); !(_step13 = _iterator13.n()).done;) {
    var _activator3 = _step13.value;

    _activator3.addEventListener('click', function () {
      var answerModal = document.getElementById('answerModal');
      uploadImageActivator = answerModal.querySelector('[data-target="uploadImageDropdown"]');
      addLinkActivator = answerModal.querySelector('[data-target="addLinkDropdown"]');
      userSelectDropdownActivator = answerModal.querySelector('[data-target="userSelectDropdown"]');
      showModal(answerModal);
      answerModal.addEventListener('click', handleAnswerModalClick);
      uploadImageActivator.nextElementSibling.addEventListener('click', stopPropagationOnDropdown);
      addLinkActivator.nextElementSibling.addEventListener('click', stopPropagationOnDropdown);
      answerModal.querySelector('#answerModalContent').addEventListener('input', handleAnswerModalContentChange);
    });
  }
} catch (err) {
  _iterator13.e(err);
} finally {
  _iterator13.f();
}

var qCoverColors = document.getElementById('qCoverColors');
var qCoverBgColor = document.getElementById('qCoverBgColor');
var qColorBtns = document.getElementsByClassName('question-modal__color-btn');
var qModalActivators = document.querySelectorAll('[data-target="qModal"]');
var qThemesDropdown = document.getElementById('qThemesDropdown');
var qCoverUploadDropdown = document.getElementById('qCoverUploadDropdown');
var qThemesDropdownActivators = document.querySelectorAll('[data-target="qThemesDropdown"]');
var qModalCloseActivators = document.querySelectorAll('[data-target-close="qModal"]');
var qUploadCoverActivator = document.querySelector('[data-target="qUploadCoverBtn"]');
var qDetailsActivators = document.querySelectorAll('[data-target="qDetails"]');
var qThemesDropdownCloseActivators = document.querySelectorAll('[data-target-close="qThemesDropdown"]');
var qCoverUploadCloseActivators = document.querySelectorAll('[data-target-close="qCoverUpload"]');
var qModalCover;
var userSelectDropdownActivator;
var selectedQColorBtnIdx = 0;

if ('autosize' in window) {
  autosize(document.querySelectorAll('textarea'));
}

function changeQCoverColor(targetEl) {
  var pressedColorBtn = targetEl.closest('.question-modal__color-btn');
  var themeChipsWrapper = qModalCover.querySelector('.theme-chips-wrapper');

  if (pressedColorBtn && qColorBtns[selectedQColorBtnIdx] !== pressedColorBtn) {
    qCoverBgColor.style.backgroundColor = pressedColorBtn.style.backgroundColor;
    qColorBtns[selectedQColorBtnIdx].firstElementChild.textContent = 'notes';
    pressedColorBtn.firstElementChild.textContent = 'done';
    selectedQColorBtnIdx = _toConsumableArray(qColorBtns).findIndex(function (qColorBtn) {
      return qColorBtn === pressedColorBtn;
    });

    if (pressedColorBtn.id == 'qColorBtnWhite') {
      qModalCover.classList.remove('question-modal__cover--white-text');
      themeChipsWrapper.classList.remove('theme-chips-wrapper--light');
    } else {
      qModalCover.classList.add('question-modal__cover--white-text');
      themeChipsWrapper.classList.add('theme-chips-wrapper--light');
    }
  }
}

function showQDetails() {
  qModalCover.insertAdjacentHTML('afterend', "\n        <textarea \n            class=\"question-modal__textarea question-modal__textarea--small\" \n            placeholder=\"F\xFCgen Sie gegebenenfalls eine detaillierte Beschreibung Ihrer Frage hinzu\"\n            rows=\"2\"\n        ></textarea>\n    ");
  qDetailsActivators.forEach(function (activator) {
    return activator.remove();
  });
  autosize(document.querySelectorAll('textarea'));
}

function handleQModalClick(event) {
  userSelectDropdownActivator.nextElementSibling.style.display = 'none';
  qThemesDropdown.style.display = 'none';
  qCoverUploadDropdown.style.display = 'none';

  var isQThemesActivatorClicked = _toConsumableArray(qThemesDropdownActivators).some(function (activator) {
    return activator.contains(event.target);
  });

  var isQModalCloseActivatorClicked = _toConsumableArray(qModalCloseActivators).some(function (activator) {
    return activator.contains(event.target);
  });

  var isQDetailsActivatorClicked = _toConsumableArray(qDetailsActivators).some(function (activator) {
    return activator.contains(event.target);
  });

  if (qCoverColors.contains(event.target)) {
    changeQCoverColor(event.target);
  } else if (isQThemesActivatorClicked) {
    qThemesDropdown.style.display = 'block';
  } else if (qUploadCoverActivator.contains(event.target)) {
    qCoverUploadDropdown.style.display = 'block';
  } else if (isQDetailsActivatorClicked) {
    showQDetails();
  } else if (userSelectDropdownActivator.contains(event.target)) {
    userSelectDropdownActivator.nextElementSibling.style.display = 'block';
  } else if (isQModalCloseActivatorClicked || !this.firstElementChild.contains(event.target)) {
    hideModal(this);
    this.removeEventListener('click', handleQModalClick);
    qThemesDropdown.removeEventListener('click', handleQThemesDropdownClick);
    qCoverUploadDropdown.removeEventListener('click', handleQUploadCoverClick);
    qModal.querySelector('#qModalTitle').removeEventListener('input', handleQModalTitleChange);
  }
}

function handleQThemesDropdownClick(event) {
  event.stopPropagation();

  var isQThemesDropdownCloseActivatorClicked = _toConsumableArray(qThemesDropdownCloseActivators).some(function (activator) {
    return activator.contains(event.target);
  });

  if (isQThemesDropdownCloseActivatorClicked) {
    qThemesDropdown.style.display = 'none';
  }
}

function handleQUploadCoverClick(event) {
  event.stopPropagation();

  var isQCoverUploadCloseActivatorClicked = _toConsumableArray(qCoverUploadCloseActivators).some(function (activator) {
    return activator.contains(event.target);
  });

  if (isQCoverUploadCloseActivatorClicked) {
    qCoverUploadDropdown.style.display = 'none';
  }
}

function handleQModalTitleChange(event) {
  var symbolsCounter = qModal.querySelector('.symbols-counter-container');
  var submitBtn = qModal.querySelector('.q-bottom-container__btn');
  var svgFillCircle = symbolsCounter.querySelector('.symbols-counter__fill-circle');
  var symbolsCounterLabel = symbolsCounter.querySelector('.symbols-counter__label__text');
  var svgStrokeDasharray = svgFillCircle.style.strokeDasharray;
  var symbolsLimit = parseInt(symbolsCounter.dataset.symbolsLimit, 10);
  var counterStep = svgStrokeDasharray / symbolsLimit;
  var questionLength = event.target.value.length;
  var newStrokeDashoffset;
  var currentSymbols;

  if (symbolsCounter.dataset.symbolsCountdown !== undefined) {
    newStrokeDashoffset = questionLength * counterStep;
    currentSymbols = symbolsLimit - questionLength;
  } else {
    newStrokeDashoffset = svgStrokeDasharray - questionLength * counterStep;
    currentSymbols = questionLength;
  }

  symbolsCounter.style.opacity = currentSymbols <= 19 ? 1 : 0;

  if (currentSymbols <= 9) {
    symbolsCounter.classList.add('symbols-counter-container--error');
  } else {
    symbolsCounter.classList.remove('symbols-counter-container--error');
  }

  symbolsCounterLabel.textContent = currentSymbols <= -999 ? -999 : currentSymbols;
  svgFillCircle.style.strokeDashoffset = newStrokeDashoffset;
  submitBtn.disabled = Boolean(currentSymbols < 0 || currentSymbols == symbolsLimit);
}

var _iterator14 = _createForOfIteratorHelper(qModalActivators),
    _step14;

try {
  for (_iterator14.s(); !(_step14 = _iterator14.n()).done;) {
    var _activator4 = _step14.value;

    _activator4.addEventListener('click', function () {
      var qModal = document.getElementById('qModal');
      qModalCover = qModal.querySelector('.question-modal__cover');
      userSelectDropdownActivator = qModal.querySelector('[data-target="userSelectDropdown"]');
      showModal(qModal);
      qModal.addEventListener('click', handleQModalClick);
      qThemesDropdown.addEventListener('click', handleQThemesDropdownClick);
      qCoverUploadDropdown.addEventListener('click', handleQUploadCoverClick);
      qModal.querySelector('#qModalTitle').addEventListener('input', handleQModalTitleChange);
    });
  }
} catch (err) {
  _iterator14.e(err);
} finally {
  _iterator14.f();
}

(function () {
  var profileAvatarModalActivators = document.querySelectorAll('[data-target="profileAvatarModal"]');
  var profileAvatarModalCloseActivators = document.querySelectorAll('[data-target-close="profileAvatarModal"]');

  function handleProfileAvatarModalClick(event) {
    var avatarModalInputClearIcon = this.querySelector('.profile-avatar-modal__input__clear-icon');

    var isProfileAvatarModalCloseClicked = _toConsumableArray(profileAvatarModalCloseActivators).some(function (activator) {
      return activator.contains(event.target);
    });

    if (event.target == avatarModalInputClearIcon) {
      avatarModalInputClearIcon.previousElementSibling.value = null;
    } else if (isProfileAvatarModalCloseClicked || !this.firstElementChild.contains(event.target)) {
      if (event.target.classList.contains('base-btn') || event.target.classList.contains('base-btn__content') ) {
          //блокируем закрывание аватарного окна при нажатии на новую кнопку
      } else {
        hideModal(this);
      }

      console.log(event.target)
      // console.log(this.firstElementChild)
      this.removeEventListener('click', handleProfileAvatarModalClick);
    }
  }

  var _iterator15 = _createForOfIteratorHelper(profileAvatarModalActivators),
      _step15;

  try {
    for (_iterator15.s(); !(_step15 = _iterator15.n()).done;) {
      var activator = _step15.value;
      activator.addEventListener('click', function () {
        var profileAvatarModal = document.getElementById('profileAvatarModal');
        showModal(profileAvatarModal);
        profileAvatarModal.addEventListener('click', handleProfileAvatarModalClick);
      });
    }
  } catch (err) {
    _iterator15.e(err);
  } finally {
    _iterator15.f();
  }
})();

(function () {
  var profileInfoModalActivators = document.querySelectorAll('[data-target="profileInformationModal"]');
  var profileInfoModalCloseActivators = document.querySelectorAll('[data-target-close="profileInformationModal"]');

  function handleProfileInfoModalClick(event) {
    var isProfileInfoModalCloseClicked = _toConsumableArray(profileInfoModalCloseActivators).some(function (activator) {
      return activator.contains(event.target);
    });

    if (isProfileInfoModalCloseClicked || !this.firstElementChild.contains(event.target)) {
      hideModal(this);
      this.removeEventListener('click', handleProfileInfoModalClick);
    }
  }

  var _iterator16 = _createForOfIteratorHelper(profileInfoModalActivators),
      _step16;

  try {
    for (_iterator16.s(); !(_step16 = _iterator16.n()).done;) {
      var activator = _step16.value;
      activator.addEventListener('click', function () {
        var profileInfoModal = document.getElementById('profileInformationModal');
        showModal(profileInfoModal);
        profileInfoModal.addEventListener('click', handleProfileInfoModalClick);
      });
    }
  } catch (err) {
    _iterator16.e(err);
  } finally {
    _iterator16.f();
  }
})();

(function () {
  var subscribeThemesModalActivators = document.querySelectorAll('[data-target="subscribeToThemesModal"]');
  var subscribeThemesModalCloseActivators = document.querySelectorAll('[data-target-close="subscribeToThemesModal"]');

  function handleSubscribeThemesModalClick(event) {
    var isSubscribeThemesModalCloseClicked = _toConsumableArray(subscribeThemesModalCloseActivators).some(function (activator) {
      return activator.contains(event.target);
    });

    if (isSubscribeThemesModalCloseClicked || !this.firstElementChild.contains(event.target)) {
      hideModal(this);
      this.removeEventListener('click', handleSubscribeThemesModalClick);
    }
  }

  var _iterator17 = _createForOfIteratorHelper(subscribeThemesModalActivators),
      _step17;

  try {
    for (_iterator17.s(); !(_step17 = _iterator17.n()).done;) {
      var activator = _step17.value;
      activator.addEventListener('click', function () {
        var subscribeThemesModal = document.getElementById('subscribeToThemesModal');
        showModal(subscribeThemesModal);
        subscribeThemesModal.addEventListener('click', handleSubscribeThemesModalClick);
      });
    }
  } catch (err) {
    _iterator17.e(err);
  } finally {
    _iterator17.f();
  }
})();

var bottomNav = document.getElementById('bottomNav');

if (bottomNav) {
  var bottomMenuActivator = bottomNav.querySelector('[data-target="bottomMenu"]');
  var prevScrollPos = window.scrollY;
  window.addEventListener('scroll', function () {
    var currentScrollPos = window.scrollY;
    bottomNav.style.bottom = "".concat(prevScrollPos > currentScrollPos ? 12 : -96, "px");
    prevScrollPos = currentScrollPos;
  });
  bottomMenuActivator.addEventListener('click', function () {
    var bottomMenu = document.getElementById(this.dataset.target);
    bottomMenu.style.display = 'block';
    showBottomSheet();
  });
}

(function () {
  var otherProfileMenuActivator = document.querySelector('[data-target="otherProfileMenu"]');
  var otherProfileMenu = document.getElementById('otherProfileMenu');
  window.addEventListener('click', function (event) {
    if (!(otherProfileMenuActivator || otherProfileMenu)) return;
    otherProfileMenu.style.display = 'none';

    if (otherProfileMenuActivator.contains(event.target) || otherProfileMenu.contains(event.target)) {
      otherProfileMenu.style.display = 'block';
    }
  });
})();

(function () {
  var updateProfileCoverActivator = document.querySelector('[data-target="profileUpdateCover"]');
  var updateProfileCoverCloseBtn = document.querySelector('[data-target-close="profileUpdateCover"]');
  var updateProfileCoverMenu = document.getElementById('profileUpdateCover');

  function handleUpdateProfileCoverMenuClick(event) {
    if (updateProfileCoverCloseBtn.contains(event.target) || !updateProfileCoverMenu.contains(event.target)) {
      updateProfileCoverMenu.style.display = 'none';
      window.removeEventListener('click', handleUpdateProfileCoverMenuClick);
    }
  }

  updateProfileCoverActivator && updateProfileCoverActivator.addEventListener('click', function (event) {
    updateProfileCoverMenu.style.display = 'block';
    event.stopPropagation();

    if (updateProfileMenu) {
      updateProfileMenu.style.display = 'none';
    }

    window.addEventListener('click', handleUpdateProfileCoverMenuClick);
  });
})();

(function () {
  var updateProfileMenuActivator = document.querySelector('[data-target="updateProfileMenu"]');
  var updateProfileMenu = document.getElementById('updateProfileMenu');

  if (updateProfileMenuActivator) {
    window.addEventListener('click', function (event) {
      var showUpdateProfileMenu = updateProfileMenuActivator.contains(event.target);
      updateProfileMenu.style.display = showUpdateProfileMenu ? 'block' : 'none';
    });
  }
})();

(function () {
  var profileTabsWrapper = document.querySelector('.profile-tabs-wrapper');
  var profileTabLinks = document.querySelectorAll('.profile-tabs__link');

  if (profileTabsWrapper) {
    var tabsContent = profileTabsWrapper.nextElementSibling.children;
    profileTabsWrapper.addEventListener('click', function (event) {
      var currentProfileTabLink = event.target.closest('.profile-tabs__link');

      if (currentProfileTabLink) {
        var hash = new URL(currentProfileTabLink.href).hash.substr(1);
        var currentTabContent = document.getElementById(hash);

        _toConsumableArray(profileTabLinks).forEach(function (profileTab) {
          return profileTab.classList.remove('profile-tabs__link--active');
        });

        _toConsumableArray(tabsContent).forEach(function (tabContent) {
          return tabContent.style.display = 'none';
        });

        currentProfileTabLink.classList.add('profile-tabs__link--active');
        currentTabContent.style.display = 'block';
        event.preventDefault();
      }
    });
  }
})();

var themesBottomMenuActivators = document.querySelectorAll('[data-target="qcThemesBottomMenu"]');

var _iterator18 = _createForOfIteratorHelper(themesBottomMenuActivators),
    _step18;

try {
  for (_iterator18.s(); !(_step18 = _iterator18.n()).done;) {
    var _activator5 = _step18.value;

    _activator5.addEventListener('click', function () {
      document.getElementById('qcThemesMenu').style.display = 'block';
      showBottomSheet();
    });
  }
} catch (err) {
  _iterator18.e(err);
} finally {
  _iterator18.f();
}